export const state = () => ({
    list: [],
    detail: [],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    setDetail (state,data){
        state.detail = data;
    },
}

export const actions = {
    async getAll({commit},payload) {
        try{
            let res = await this.$axios.$post('/publication-downloader',payload)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getDetail({commit},payload) {
        try{
            let res = await this.$axios.$post('/publication-downloader-detail',payload)
            if(res.status){
                commit('setDetail', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}