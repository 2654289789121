export const state = () => ({
    list: [],
    single:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
}

export const actions = {
    async getAll({commit},payload){
        try{
            let res = await this.$axios.$get('/settings');
            if(res.status){
                commit('setList',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/settings/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async createUpdateAll({commit},payload){
        try{
            let res = await this.$axios.$post('/settings/createUpdateAll',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/settings/update',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/settings/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}