export const state = () => ({
    list: []
});
  
export const mutations = {
    set (state,data){
        state.list = data;
    },
}

export const actions = {
    async get({commit},payload) {
        try{
            let res = await this.$axios.$get('/languages');
            if(res.status){
                commit('set', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}