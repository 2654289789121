export const state = () => ({
    list: [],
    single:[],
    uploading:[],
    gallery:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
    setUploading(state,data){
        state.uploading = data;
    },
    setGallery(state,data){
        state.gallery = data;
    }
}

export const actions = {
    async getAllByLanguage({commit},payload) {
        try{
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/kadinpedia/'+payload.language_id+'?'+queryString)
            if(res.status){
                if(typeof payload.single!='undefined' && payload.single){
                    commit('set',res.data);
                }
                else{
                    commit('setList', res.data);
                }
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getGallery({commit},payload){
        try{
            let res = await this.$axios.$get('/kadinpedia/gallery/'+payload.id);
            if(res.status){
                commit('setGallery',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async updateGallery({commit},payload){
        try{
            let res = await this.$axios.$post('/kadinpedia/gallery-update/'+payload.id,{gallery:payload.gallery,delete:payload.delete});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/kadinpedia/'+payload.language_id+'/'+payload.id+'/'+payload.slug);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/kadinpedia/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/kadinpedia/create',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/kadinpedia/update',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/kadinpedia/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async togglePublish({commit},payload){
        try{
            let res = await this.$axios.$post('/kadinpedia/toggle-publish',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async setUploading({commit},payload){
        commit('setUploading',payload);
    },
    async updateOrder({commit},payload){
        try{
            let res = await this.$axios.$post('/kadinpedia/updateOrder',{data:payload});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}}
        }
    },
}