export const state = () => ({
    list: [],
    single:[],
    gallery:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    setGallery (state,data){
        state.gallery = data;
    },
    set(state,data){
        state.single = data;
    }
}

export const actions = {
    async getGallery({commit},payload){
        try{
            let res = await this.$axios.$get('/gallery/gallery/'+payload.id);
            if(res.status){
                commit('setGallery',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getGalleryByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/gallery/'+payload.language_id+'/'+payload.id);
            if(res.status){
                commit('setGallery',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async updateGallery({commit},payload){
        try{
            let res = await this.$axios.$post('/gallery/gallery-update/'+payload.id,{gallery:payload.gallery,delete:payload.delete});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getAllByLanguage({commit},payload) {
        try{
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/gallery/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/gallery/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/gallery/create',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/gallery/update',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/gallery/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async updateOrder({commit},payload){
        try{
            let res = await this.$axios.$post('/gallery/updateOrder',{data:payload});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}}
        }
    },
    async togglePublish({commit},payload){
        try{
            let res = await this.$axios.$post('/gallery/toggle-publish',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}}
        }
    }
}