export const state = () => ({
    list: [],
    single:[]
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    }
}

export const actions = {
    async getAll({commit,dispatch},payload) {
        try{
            let queryString = payload ? await dispatch('getQueryString',payload) : '';
            let res = await this.$axios.$get('/categories/getAll?'+queryString)
            if(res.status){
                commit('setList', {data:res.data});
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getAllByLanguage({commit,dispatch},payload) {
        try{
            let queryString = payload ? await dispatch('getQueryString',payload) : '';
            let res = await this.$axios.$get('/categories/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/categories/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/categories/create',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/categories/update',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/categories/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async toggleShowInHomepage({commit},payload){
        try{
            let res = await this.$axios.$post('/categories/toggle-show-in-homepage',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getQueryString({commit},object){
        let queryString='';
        for(var i in object){
            if(Array.isArray(object[i])){
                for(var j in object[i]){
                    queryString+=i+'='+object[i][j]+'&';
                }
            }
                else{
                queryString+=i+'='+object[i]+'&';
            }
        }
        return queryString;
    },
    async updateOrder({commit},payload){
        try{
            let res = await this.$axios.$post('/categories/updateOrder',{data:payload});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}}
        }
    },
}