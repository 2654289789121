export const state = () => ({
  list: [],
});

export const mutations = {
  setList(state, data) {
    state.list = data;
  },
}

export const actions = {
  async getAll({ commit }, payload) {
    try {
      let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/seminarciptakerja/?' + queryString)
      if (res.status) {
        commit('setList', res.data);
      }
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async register({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/seminarciptakerja', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async resendEmail({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/seminarciptakerja-resend-email', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
}