export const state = () => ({
  list: [],
  single: [],
});

export const mutations = {
  setList(state, data) {
    state.list = data;
  },
  set(state, data) {
    state.single = data;
  },
}

export const actions = {
  async getAll({ commit }, payload) {
    try {
      var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/newscovid19/getAll?' + queryString)
      if (res.status) {
        commit('setList', res.data);
      }
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async get({ commit }, payload) {
    try {
      let res = await this.$axios.$get('/newscovid19/get/' + payload.id);
      if (res.status) {
        commit('set', res.data);
      }
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async create({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/newscovid19/create', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async update({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/newscovid19/update', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async delete({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/newscovid19/delete', { id: payload.id });
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async togglePublish({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/newscovid19/toggle-publish', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
}