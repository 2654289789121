export const state = () => ({
    list: [],
    single:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    }
}

export const actions = {
    async getAllByLanguage({commit},payload) {
        try{
            let res = await this.$axios.$get('/events-participants/'+payload.language_id+'/'+payload.event_id+'?page='+payload.page+'&limit='+payload.limit+'&sortBy='+payload.sortBy+'&sort='+payload.sort+'&search='+payload.search)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/events-participants/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/events-participants/create',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/events-participants/update',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/events-participants/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}