export const state = () => ({
    list: [],
    single:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
}

export const actions = {
    async getAllByLanguage({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/articles/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/articles/'+payload.language_id+'/'+payload.slug);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async togglePublish({commit},payload){
        try{
            let res = await this.$axios.$post('/articles/toggle-publish',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}