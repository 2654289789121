export const state = () => ({
    list: [],
    single:[],
    uploading:[],
    files:[],
    file:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
    setUploading(state,data){
        state.uploading = data;
    },
    setFiles(state,data){
        state.files = data;
    },
    setFile(state,data){
        state.file = data;
    }
}

export const actions = {
    async filesGetAllByLanguage({commit},payload) {
        try{
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/publication-files/'+payload.language_id+'/'+payload.publication_id+'?'+queryString)
            if(res.status){
                if(typeof payload.single!='undefined' && payload.single){
                    commit('setFile',res.data);
                }
                else{
                    commit('setFiles', res.data);
                }
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async filesGetByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/publication-files-get/'+payload.language_id+'/'+payload.id);
            if(res.status){
                commit('setFiles',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async detailFilesGetByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/publication-files-get-detail/'+payload.language_id+'/'+payload.id);
            if(res.status){
                commit('setFile',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async filesCreate({commit},payload){
        try{
            let res = await this.$axios.$post('/publication-files-upload',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async filesUpdate({commit},payload){
        try{
            let res = await this.$axios.$post('/publication-files-update',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async filesDelete({commit},payload){
        try{
            let res = await this.$axios.$post('/publication-files-delete',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getAllByLanguage({commit},payload) {
        try{
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/publication/'+payload.language_id+'?'+queryString)
            if(res.status){
                if(typeof payload.single!='undefined' && payload.single){
                    commit('set',res.data);
                }
                else{
                    commit('setList', res.data);
                }
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getFile({commit},payload){
        try{
            let res = await this.$axios.$get('/publication/file/'+payload.id);
            if(res.status){
                commit('setFile',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async updateFile({commit},payload){
        try{
            let res = await this.$axios.$post('/publication/file-update/'+payload.id,{file:payload.file,delete:payload.delete});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/publication/'+payload.language_id+'/'+payload.id+'/'+payload.slug);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/publication/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/publication/create',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/publication/update',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/publication/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async togglePublish({commit},payload){
        try{
            let res = await this.$axios.$post('/publication/toggle-publish',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async setUploading({commit},payload){
        commit('setUploading',payload);
    },
}