<template>
    <div>
      <header>
        <b-container fluid class="padding-fluid">
          <b-navbar toggleable="md" type="light">
            <b-navbar-brand :to="localePath('index')">
              <!-- <img v-lazy="require('~/static/logo-kadin.png')" class="new" alt="Kadin"> -->
              <img src="/logo-kadin-indonesia.png" class="new" alt="Kadin, Kadin Indonesia, logo Kadin indonesia">
            </b-navbar-brand>

            <b-navbar-nav class="opt">
              <b-nav-item target="_blank" href="https://cmis.kadin.id/login" class="d-none">{{$t('pages.commodity')}} <img v-lazy="require('~/static/caret.png')" alt="Kadin"> </b-nav-item>
              <b-nav-form @submit="onSearch" novalidate>      
                    <a v-on:click='isOpen = !isOpen' class="search-responsive"><img v-lazy="require('~/static/search-ico.png')" alt="Kadin"></a>
                    <input type="text" class="form-control search" v-show="isOpen" name="search" v-model="search" id="search" placeholder="Cari....">
              </b-nav-form>
              <!-- <b-nav-item-dropdown right>
                <template slot="button-content">
                  {{$i18n.locale.toUpperCase()}}<img :src="'/'+$i18n.locale+'.png'" alt="Kadin">
                </template>
                <b-dropdown-item v-for="locale in $i18n.locales" :to="switchLocalePath(locale.code)" :key="locale.code">
                    {{ locale.code.toUpperCase() }} <img v-lazy="require('~/static/'+locale.code+'.png')" alt="Kadin">
                </b-dropdown-item>
              </b-nav-item-dropdown> -->
            </b-navbar-nav>

            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>


            <b-collapse is-nav id="nav_collapse">
              <b-navbar-nav>
                <b-nav-item-dropdown :text="this.$i18n.t('pages.tentangkami')">
                  <b-dropdown-item :to="localePath('tentang-kami-sejarah')">{{$t('pages.sejarah')}}</b-dropdown-item>
                  <b-dropdown-item :to="localePath('tentang-kami-uu-ad-art-kadin')">{{$t('pages.uuadartkadin')}}</b-dropdown-item>
                  <b-dropdown-item :to="localePath('tentang-kami-peraturan-pedoman-organisasi')">{{$t('pages.peraturan')}}</b-dropdown-item>
                  <b-dropdown-item :to="localePath('tentang-kami-lambang-mars-hymne')">{{$t('pages.lambangmarshymne')}}</b-dropdown-item>
                  <b-dropdown-item :to="localePath('tentang-kami-struktur-organisasi')">{{$t('pages.strukturorganisasi')}}</b-dropdown-item>
                  <b-dropdown-item :to="localePath('tentang-kami-visi-misi')">{{$t('pages.visimisi')}}</b-dropdown-item>
                  <b-dropdown-item :to="localePath({name:'kadinpedia-list'})">{{$t('pages.kadinpedia')}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown :text="this.$i18n.t('pages.layanan')">
                  <b-dropdown-item v-for="data in menu.layanan" :key="data.id" :to="localePath({name:'layanan-id-slug',params:{id:data.id,slug:data.slug}})">{{data.title}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown :text="this.$i18n.t('pages.keanggotaan')">
                  <b-dropdown-item v-for="data in menu.keanggotaan" :key="data.id" :to="localePath({name:'keanggotaan-id-slug',params:{id:data.id,slug:data.slug}})">{{data.title}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <!-- <b-nav-item :to="localePath({name:'kadintalks-list'})">{{$t('pages.kadintalks')}}</b-nav-item> -->
                <b-nav-item-dropdown :text="this.$i18n.t('pages.eventberita')">
                  <template v-for="data in menu.event">
                    <b-dropdown-item v-if="data.category_type=='event'" :key="data.id" :to="localePath({name:'news-event-event-list-cat_slug',params:{cat_slug:data.slug}})">{{data.name}}</b-dropdown-item>
                  </template>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown :text="this.$i18n.t('pages.publikasi')">
                  <template v-for="(data) in menu.publikasi">
                    <b-dropdown-item v-if="data.category_type=='publikasi'" :key="data.id" :to="localePath({name:'publikasi-list-id-cat_slug',params:{id:data.id,cat_slug:data.slug}})">{{data.name}}</b-dropdown-item>
                    <b-dropdown-item v-if="data.category_type=='galeri'" :key="data.id" :to="localePath({name:'news-event-gallery-list-id-slug',params:{id:data.id,slug:data.slug}})">{{data.name}}</b-dropdown-item>
                    <b-dropdown-item v-if="data.category_type=='berita'" :key="data.id" :to="localePath({name:'news-event-news-list-cat_slug',params:{cat_slug:data.slug}})">{{data.name}}</b-dropdown-item>
                    <!-- <b-dropdown-item v-if="index === menu.publikasi.length -1" :to="localePath({name:'emagazine'})" :key="index + 'emagazine'">E-Magazine</b-dropdown-item> -->
                  </template>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-container>
      </header>

      <div class="content-body"> 
        <nuxt/>
      </div>

      <footer>
        <!-- <div class="kadin-address" style="background-image: url('/essence.png')" v-show="$route.name=='index___id' || $route.name=='index___en'"> -->
        <div class="kadin-address" style="background-image: url('/footer-bg.jpg')">
          <b-container fluid class="padding-fluid">
            <b-row>
              <b-col lg="6" md="12">
                <img src="/logo-long.png" alt="" class="img-fluid logo-footer">
              </b-col>
              <b-col lg="6" md="12">
                <div class="wrap">
                  <!-- <h5><img v-lazy="require('~/static/loc_icon.svg')" alt="Location Icon"></h5> -->
                  <img src="/loc_icon.svg" alt="Location Icon">
                  <p><a target="_blank" href="https://maps.app.goo.gl/hfzai5nUgZvDjUfE9">Kantor Kamar Dagang dan Industri (KADIN) Indonesia,<br> Menara Kadin Indonesia Lt. 3 Jalan HR. Rasuna Said X-5 Kav 2-3,<br> Jakarta 12950 - Indonesia</a></p>
                </div>
              </b-col>
            </b-row>
            <b-row class="align-items-center">
              <b-col lg="6" md="12" class="other-link">
                <nuxt-link to="/kebijakan-privasi">{{$t('pages.kebijakanprivasi')}}</nuxt-link>
                <nuxt-link to="/syarat-ketentuan">{{$t('pages.syaratketentuan')}}</nuxt-link>
                <nuxt-link :to="localePath('kontak-kami')">{{$t('pages.kontakkami')}}</nuxt-link>
              </b-col>
              <b-col lg="6" md="12" class="socmed">
                  <a v-show="typeof settings.facebook_url!='undefined' && settings.facebook_url!=''" :href="typeof settings.facebook_url!='undefined' ? settings.facebook_url : '#'"><i class="fab fa-facebook-f"></i></a>
                  <a v-show="typeof settings.twitter_url!='undefined' && settings.twitter_url!=''" :href="typeof settings.twitter_url!='undefined' ? settings.twitter_url : '#'"><i class="fab fa-twitter"></i></a>
                  <a v-show="typeof settings.youtube_url!='undefined' && settings.youtube_url!=''" :href="typeof settings.youtube_url!='undefined' ? settings.youtube_url : '#'"><i class="fab fa-youtube"></i></a>
                  <a v-show="typeof settings.instagram_url!='undefined'&& settings.instagram_url!=''" :href="typeof settings.instagram_url!='undefined' ? settings.instagram_url : '#'"><i class="fab fa-instagram"></i></a>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="cright">
                <p>Copyright © Kadin.id {{new Date().getFullYear()}}. All rights reserved.</p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="kadin-bsd d-none" style="background-image: url('/footer-bg.png')" v-show="$route.name!='index___id' && $route.name!='index___en'">
          <b-container>
            <b-row>
              <b-col cols="12">
                <div class="wrap">
                  <h4>KADIN Business Service Desk</h4>
                  <h5>Indonesian Chamber of Commerce and Industry</h5>
                </div>
                <a target="_blank" href="https://bsd-kadin.org/">
                  {{$t('messages.kunjungikadinbsd')}} 
                  <img v-lazy="require('~/static/arrow-rwhite.png')" alt="Kadin">
                </a>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </footer>
    </div>
</template>

<script>
export default {
  computed: {
    settings() {
      let data = this.$store.state.pageSettings
      let form = {}
      data.forEach(d => {
        form[d.key] = d.value
      })
      return form
    },
    menu() {
      return this.$store.state.subMenu
    }
  },
  data: function() {
    return {
      isOpen: '',
      search: ''
    }
  },
  methods: {
    onSearch(evt) {
      evt.preventDefault()
      if (this.search && this.search != '') {
        this.$router.push(
          this.localePath({
            name: 'cari-text',
            params: {
              text: this.search
            }
          })
        )
      }
    }
  }
}
</script>
