const cookieparser = process.server ? require('cookieparser') : undefined
const Cookie = process.client ? require('js-cookie') : undefined

export const strict = false
export const state = () => ({
    auth: [],
    initialLimit:10,
    pageSettings:[],
    trumboConfig:{},
    subMenu:{},
    locale:'',
});
  
export const mutations = {
    setAuth : function(state, auth) {
        state.auth = auth
    },
    setSettings : function(state, data) {
        state.pageSettings = data
    },
    setTrumboConfig : function(state, data) {
        state.trumboConfig = data
    },
    setSubMenu : function(state, payload) {
        state.subMenu=payload
    },
    SET_LOCALE: (state, locale) => state.locale = locale,
}

export const actions = {
    trumboConfig:async function({commit},payload){
        commit('setTrumboConfig',{
            semantic: false,
            btnsDef: {
                // Create a new dropdown
                image: {
                    dropdown: ['insertImage', 'upload'],
                    ico: 'insertImage'
                }
            },
            // Redefine the button pane
            btns: [
                ['viewHTML'],
                ['formatting'],
                ['strong', 'em', 'del'],
                ['foreColor', 'backColor'],
                ['superscript', 'subscript'],
                ['link'],
                ['image'], // Our fresh created dropdown
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
                ['fullscreen']
            ],
            plugins: {
                // Add imagur parameters to upload plugin for demo purposes
                upload: {
                    serverPath: process.env.API_URL+'/assets/trumbowyg/upload/image',
                    fileFieldName: 'image',
                    headers: {
                        'Authorization': 'Bearer '+ payload
                    },
                    urlPropertyName: 'url'
                }
            }
        })
    },
    nuxtServerInit: async function({ commit,dispatch}, { req }) {
        let auth = null
        if (req.headers.cookie) {
            const parsed = cookieparser.parse(req.headers.cookie)
            try {
                auth = JSON.parse(parsed.auth);
            } catch (err) {
                // No valid cookie found
            }
        }
        commit('setAuth', auth);
        let token = auth && typeof auth.accessToken!='undefined' && auth.accessToken!='' ? auth.accessToken : '';
        await dispatch('trumboConfig',token);
        let res = await this.$axios.$get('/settings');
        if(res.status){
            commit('setSettings',res.data);
        }
    },
    login: async function({commit,dispatch},payload){
        try{
            const resp = await this.$axios.$post('/login',payload);
            if(resp.status){
                const auth = {
                    accessToken: resp.token
                }
                let time = new Date(new Date().getTime() + 180 * 60 * 1000);
                Cookie.set('auth', auth,{expires: time}); // saving token in cookie for server rendering
                commit('setAuth', auth); // mutating to store for client rendering
                let token = auth && typeof auth.accessToken!='undefined' && auth.accessToken!='' ? auth.accessToken : '';
                await dispatch('trumboConfig',token);
            }
            return resp;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    }
}