export const state = () => ({
    recentNews: [],
    recentEvents: [],
    recentArticles: [],
});
  
export const mutations = {
    setRecentNews(state,data){
        state.recentNews = data;
    },
    setRecentEvents(state,data){
        state.recentEvents = data;
    },
    setRecentArticles(state,data){
        state.recentArticles = data;
    }
}

export const actions = {
    async getRecentNews({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/news/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setRecentNews', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getRecentEvents({commit},payload) {
        try{
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/events/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setRecentEvents', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getRecentArticles({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/articles/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setRecentArticles', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}