export const state = () => ({
    single:[],
});
  
export const mutations = {
    set(state,data){
        state.single = data;
    }
}

export const actions = {
    async getByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/visimisi/get/'+payload.language_id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/visimisi/get');
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/visimisi/create',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}