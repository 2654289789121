async function setSubMenu(app,lang){
    let data={
        layanan:[],
        keanggotaan:[],
        event:[],
        publikasi:[],
    };
    let layanan = await app.$axios.$get('/services/'+lang+'?page=1&limit=999999999&sortBy=published_at&sort=DESC&published=1');
    data['layanan'] = layanan.status ? layanan.data.data : {};
    let members = await app.$axios.$get('/members/'+lang+'?page=1&limit=999999999&sortBy=published_at&sort=DESC&published=1');
    data['keanggotaan'] = members.status ? members.data.data : {};
    let event = await app.$axios.$get('/categories/'+lang+'?page=1&limit=999999999&sortBy=order&sort=ASC&published=1category_type=event');
    data['event'] = event.status ? event.data.data : {};
    let publikasi = await app.$axios.$get('/categories/'+lang+'?page=1&limit=999999999&sortBy=order&sort=ASC&published=1&category_type=publikasi&category_type=galeri&category_type=berita');
    data['publikasi'] = publikasi.status ? publikasi.data.data : {};
    app.store.commit('setSubMenu',data);
}
export default async function ({ app }) {
    app.store.commit('SET_LOCALE', app.i18n.locale);
    app.i18n.beforeLanguageSwitch = async (oldLocale, newLocale) => {
        app.store.commit('SET_LOCALE', newLocale);
        await setSubMenu(app,newLocale);
    }
    await setSubMenu(app,app.i18n.locale);
}  