export const state = () => ({
    news: [],
    events: [],
    keanggotaan: [],
    kadintalks: [],
    kadinpedia: [],
    layanan: [],
    publikasi: [],
});
  
export const mutations = {
    setNews (state,data){
        state.news = data;
    },
    setEvents (state,data){
        state.events = data;
    },
    setKadintalks (state,data){
        state.kadintalks = data;
    },
    setKadinpedia (state,data){
        state.kadinpedia = data;
    },
    setLayanan (state,data){
        state.layanan = data;
    },
    setKeanggotaan (state,data){
        state.keanggotaan = data;
    },
    setPublikasi (state,data){
        state.publikasi = data;
    },
}

export const actions = {
    async getNews({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/news/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setNews', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getEvents({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/events/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setEvents', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getKadintalks({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/kadintalks/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setKadintalks', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getKadinpedia({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/kadinpedia/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setKadinpedia', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getLayanan({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/services/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setLayanan', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getKeanggotaan({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/members/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setKeanggotaan', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getPublikasi({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/publication/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setPublikasi', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}