import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _153fe3ce = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _0477c2fa = () => interopDefault(import('../pages/berita-seputar-kadin/index.vue' /* webpackChunkName: "pages/berita-seputar-kadin/index" */))
const _0c674b1c = () => interopDefault(import('../pages/covid-19/index.vue' /* webpackChunkName: "pages/covid-19/index" */))
const _8038b47a = () => interopDefault(import('../pages/emagazine/index.vue' /* webpackChunkName: "pages/emagazine/index" */))
const _13c27468 = () => interopDefault(import('../pages/karir.vue' /* webpackChunkName: "pages/karir" */))
const _ac8bab68 = () => interopDefault(import('../pages/keanggotaan/index.vue' /* webpackChunkName: "pages/keanggotaan/index" */))
const _7b34d3a2 = () => interopDefault(import('../pages/kebijakan-privasi.vue' /* webpackChunkName: "pages/kebijakan-privasi" */))
const _d0a8d15c = () => interopDefault(import('../pages/kontak-kami.vue' /* webpackChunkName: "pages/kontak-kami" */))
const _2ab85d08 = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _2179bc9a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6bca592c = () => interopDefault(import('../pages/news-event/index.vue' /* webpackChunkName: "pages/news-event/index" */))
const _0c1770f0 = () => interopDefault(import('../pages/publikasi/index.vue' /* webpackChunkName: "pages/publikasi/index" */))
const _1371e9ed = () => interopDefault(import('../pages/rapimnas2019/index.vue' /* webpackChunkName: "pages/rapimnas2019/index" */))
const _9d0a9e9a = () => interopDefault(import('../pages/seminarciptakerja/index.vue' /* webpackChunkName: "pages/seminarciptakerja/index" */))
const _2525b977 = () => interopDefault(import('../pages/syarat-ketentuan.vue' /* webpackChunkName: "pages/syarat-ketentuan" */))
const _280a37a0 = () => interopDefault(import('../pages/undangan-rapimnas-2019.vue' /* webpackChunkName: "pages/undangan-rapimnas-2019" */))
const _6ce39a49 = () => interopDefault(import('../pages/admin/articles/index.vue' /* webpackChunkName: "pages/admin/articles/index" */))
const _7c207b28 = () => interopDefault(import('../pages/admin/categories/index.vue' /* webpackChunkName: "pages/admin/categories/index" */))
const _aa32cdc8 = () => interopDefault(import('../pages/admin/covid-19/index.vue' /* webpackChunkName: "pages/admin/covid-19/index" */))
const _1d63d07a = () => interopDefault(import('../pages/admin/emagazine/index.vue' /* webpackChunkName: "pages/admin/emagazine/index" */))
const _031c1e3b = () => interopDefault(import('../pages/admin/emailbox/index.vue' /* webpackChunkName: "pages/admin/emailbox/index" */))
const _d7654336 = () => interopDefault(import('../pages/admin/events/index.vue' /* webpackChunkName: "pages/admin/events/index" */))
const _579f6541 = () => interopDefault(import('../pages/admin/files/index.vue' /* webpackChunkName: "pages/admin/files/index" */))
const _4f2dac9c = () => interopDefault(import('../pages/admin/gallery/index.vue' /* webpackChunkName: "pages/admin/gallery/index" */))
const _38a2128c = () => interopDefault(import('../pages/admin/homeslider/index.vue' /* webpackChunkName: "pages/admin/homeslider/index" */))
const _851eb440 = () => interopDefault(import('../pages/admin/kadinpedia/index.vue' /* webpackChunkName: "pages/admin/kadinpedia/index" */))
const _ce118e80 = () => interopDefault(import('../pages/admin/kadintalks/index.vue' /* webpackChunkName: "pages/admin/kadintalks/index" */))
const _79749cba = () => interopDefault(import('../pages/admin/members/index.vue' /* webpackChunkName: "pages/admin/members/index" */))
const _0c816042 = () => interopDefault(import('../pages/admin/news/index.vue' /* webpackChunkName: "pages/admin/news/index" */))
const _38cc9f76 = () => interopDefault(import('../pages/admin/publication/index.vue' /* webpackChunkName: "pages/admin/publication/index" */))
const _b65998c8 = () => interopDefault(import('../pages/admin/publication-download-history/index.vue' /* webpackChunkName: "pages/admin/publication-download-history/index" */))
const _525322b3 = () => interopDefault(import('../pages/admin/seminarciptakerja/index.vue' /* webpackChunkName: "pages/admin/seminarciptakerja/index" */))
const _47c539ac = () => interopDefault(import('../pages/admin/services/index.vue' /* webpackChunkName: "pages/admin/services/index" */))
const _3a8edcaf = () => interopDefault(import('../pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _ed06b000 = () => interopDefault(import('../pages/kadinpedia/list.vue' /* webpackChunkName: "pages/kadinpedia/list" */))
const _292f8dc0 = () => interopDefault(import('../pages/kadintalks/list.vue' /* webpackChunkName: "pages/kadintalks/list" */))
const _3a7eed9f = () => interopDefault(import('../pages/rapimnas2019/kedatangan.vue' /* webpackChunkName: "pages/rapimnas2019/kedatangan" */))
const _2386cc19 = () => interopDefault(import('../pages/rapimnas2019/konfirmasi-pembayaran.vue' /* webpackChunkName: "pages/rapimnas2019/konfirmasi-pembayaran" */))
const _0b22fbf2 = () => interopDefault(import('../pages/rapimnas2019/landing.vue' /* webpackChunkName: "pages/rapimnas2019/landing" */))
const _6612bde3 = () => interopDefault(import('../pages/seminarciptakerja/pendaftaran.vue' /* webpackChunkName: "pages/seminarciptakerja/pendaftaran" */))
const _47998c5c = () => interopDefault(import('../pages/tentang-kami/lambang-mars-hymne.vue' /* webpackChunkName: "pages/tentang-kami/lambang-mars-hymne" */))
const _63e28282 = () => interopDefault(import('../pages/tentang-kami/peraturan-pedoman-organisasi/index.vue' /* webpackChunkName: "pages/tentang-kami/peraturan-pedoman-organisasi/index" */))
const _def62b34 = () => interopDefault(import('../pages/tentang-kami/sejarah.vue' /* webpackChunkName: "pages/tentang-kami/sejarah" */))
const _0c9b2495 = () => interopDefault(import('../pages/tentang-kami/struktur-organisasi.vue' /* webpackChunkName: "pages/tentang-kami/struktur-organisasi" */))
const _3eff0365 = () => interopDefault(import('../pages/tentang-kami/uu-ad-art-kadin/index.vue' /* webpackChunkName: "pages/tentang-kami/uu-ad-art-kadin/index" */))
const _44f9f128 = () => interopDefault(import('../pages/tentang-kami/visi-misi.vue' /* webpackChunkName: "pages/tentang-kami/visi-misi" */))
const _cbbf8022 = () => interopDefault(import('../pages/admin/about-us/history/index.vue' /* webpackChunkName: "pages/admin/about-us/history/index" */))
const _2b2d9935 = () => interopDefault(import('../pages/admin/about-us/hymn-march-symbol.vue' /* webpackChunkName: "pages/admin/about-us/hymn-march-symbol" */))
const _13de8231 = () => interopDefault(import('../pages/admin/about-us/organization-structure.vue' /* webpackChunkName: "pages/admin/about-us/organization-structure" */))
const _3aefd6dc = () => interopDefault(import('../pages/admin/about-us/rules/index.vue' /* webpackChunkName: "pages/admin/about-us/rules/index" */))
const _4f75cdc7 = () => interopDefault(import('../pages/admin/about-us/uu-ad-art-kadin/index.vue' /* webpackChunkName: "pages/admin/about-us/uu-ad-art-kadin/index" */))
const _511cd4ce = () => interopDefault(import('../pages/admin/about-us/visi-misi.vue' /* webpackChunkName: "pages/admin/about-us/visi-misi" */))
const _2fa33496 = () => interopDefault(import('../pages/admin/categories/create.vue' /* webpackChunkName: "pages/admin/categories/create" */))
const _60292b5b = () => interopDefault(import('../pages/admin/emagazine/create.vue' /* webpackChunkName: "pages/admin/emagazine/create" */))
const _1d12b8f9 = () => interopDefault(import('../pages/admin/events/create.vue' /* webpackChunkName: "pages/admin/events/create" */))
const _77fce4c6 = () => interopDefault(import('../pages/admin/files/create.vue' /* webpackChunkName: "pages/admin/files/create" */))
const _83879cbc = () => interopDefault(import('../pages/admin/gallery/create.vue' /* webpackChunkName: "pages/admin/gallery/create" */))
const _035489b2 = () => interopDefault(import('../pages/admin/homeslider/create.vue' /* webpackChunkName: "pages/admin/homeslider/create" */))
const _185860de = () => interopDefault(import('../pages/admin/kadinpedia/create.vue' /* webpackChunkName: "pages/admin/kadinpedia/create" */))
const _a4b7ac04 = () => interopDefault(import('../pages/admin/kadintalks/create.vue' /* webpackChunkName: "pages/admin/kadintalks/create" */))
const _65b6650a = () => interopDefault(import('../pages/admin/members/create.vue' /* webpackChunkName: "pages/admin/members/create" */))
const _65def6bf = () => interopDefault(import('../pages/admin/news/create.vue' /* webpackChunkName: "pages/admin/news/create" */))
const _087b9a08 = () => interopDefault(import('../pages/admin/publication/create.vue' /* webpackChunkName: "pages/admin/publication/create" */))
const _61796658 = () => interopDefault(import('../pages/admin/services/create.vue' /* webpackChunkName: "pages/admin/services/create" */))
const _2a0ab903 = () => interopDefault(import('../pages/admin/about-us/history/ketuakadin/index.vue' /* webpackChunkName: "pages/admin/about-us/history/ketuakadin/index" */))
const _d3a26f28 = () => interopDefault(import('../pages/admin/about-us/rules/create.vue' /* webpackChunkName: "pages/admin/about-us/rules/create" */))
const _720f9452 = () => interopDefault(import('../pages/admin/about-us/uu-ad-art-kadin/create.vue' /* webpackChunkName: "pages/admin/about-us/uu-ad-art-kadin/create" */))
const _3f00b21b = () => interopDefault(import('../pages/admin/about-us/history/ketuakadin/create.vue' /* webpackChunkName: "pages/admin/about-us/history/ketuakadin/create" */))
const _07d3a52a = () => interopDefault(import('../pages/admin/about-us/history/ketuakadin/translate/_id.vue' /* webpackChunkName: "pages/admin/about-us/history/ketuakadin/translate/_id" */))
const _190001b9 = () => interopDefault(import('../pages/admin/about-us/rules/translate/_id.vue' /* webpackChunkName: "pages/admin/about-us/rules/translate/_id" */))
const _6f4fb5ee = () => interopDefault(import('../pages/admin/about-us/uu-ad-art-kadin/translate/_id.vue' /* webpackChunkName: "pages/admin/about-us/uu-ad-art-kadin/translate/_id" */))
const _82291b62 = () => interopDefault(import('../pages/admin/categories/translate/_id.vue' /* webpackChunkName: "pages/admin/categories/translate/_id" */))
const _21345508 = () => interopDefault(import('../pages/admin/events/participants/_id.vue' /* webpackChunkName: "pages/admin/events/participants/_id" */))
const _7c1657a6 = () => interopDefault(import('../pages/admin/events/registrants/_id.vue' /* webpackChunkName: "pages/admin/events/registrants/_id" */))
const _24586ee8 = () => interopDefault(import('../pages/admin/events/translate/_id.vue' /* webpackChunkName: "pages/admin/events/translate/_id" */))
const _7d39efc3 = () => interopDefault(import('../pages/admin/gallery/translate/_id.vue' /* webpackChunkName: "pages/admin/gallery/translate/_id" */))
const _9abd349a = () => interopDefault(import('../pages/admin/homeslider/translate/_id.vue' /* webpackChunkName: "pages/admin/homeslider/translate/_id" */))
const _879ce6ea = () => interopDefault(import('../pages/admin/kadinpedia/gallery/_id.vue' /* webpackChunkName: "pages/admin/kadinpedia/gallery/_id" */))
const _7ba16507 = () => interopDefault(import('../pages/admin/kadinpedia/translate/_id.vue' /* webpackChunkName: "pages/admin/kadinpedia/translate/_id" */))
const _345717e7 = () => interopDefault(import('../pages/admin/kadintalks/translate/_id.vue' /* webpackChunkName: "pages/admin/kadintalks/translate/_id" */))
const _8548046c = () => interopDefault(import('../pages/admin/members/translate/_id.vue' /* webpackChunkName: "pages/admin/members/translate/_id" */))
const _6761a006 = () => interopDefault(import('../pages/admin/news/translate/_id.vue' /* webpackChunkName: "pages/admin/news/translate/_id" */))
const _069238c9 = () => interopDefault(import('../pages/admin/publication/files/_id/index.vue' /* webpackChunkName: "pages/admin/publication/files/_id/index" */))
const _47e52ec6 = () => interopDefault(import('../pages/admin/publication/translate/_id.vue' /* webpackChunkName: "pages/admin/publication/translate/_id" */))
const _dfed8f5e = () => interopDefault(import('../pages/admin/services/translate/_id.vue' /* webpackChunkName: "pages/admin/services/translate/_id" */))
const _192da9d6 = () => interopDefault(import('../pages/admin/publication/files/_id/create.vue' /* webpackChunkName: "pages/admin/publication/files/_id/create" */))
const _2f19124b = () => interopDefault(import('../pages/admin/kadintalks/bumper/_id/_slug.vue' /* webpackChunkName: "pages/admin/kadintalks/bumper/_id/_slug" */))
const _6aa4f2ed = () => interopDefault(import('../pages/admin/publication/files/_id/_fileid.vue' /* webpackChunkName: "pages/admin/publication/files/_id/_fileid" */))
const _5b224ab7 = () => interopDefault(import('../pages/news-event/gallery/list/_id/_slug.vue' /* webpackChunkName: "pages/news-event/gallery/list/_id/_slug" */))
const _54362604 = () => interopDefault(import('../pages/admin/gallery/_id.vue' /* webpackChunkName: "pages/admin/gallery/_id" */))
const _13916d04 = () => interopDefault(import('../pages/admin/publication-download-history/_id.vue' /* webpackChunkName: "pages/admin/publication-download-history/_id" */))
const _2532c34b = () => interopDefault(import('../pages/news-event/event-list/_cat_slug.vue' /* webpackChunkName: "pages/news-event/event-list/_cat_slug" */))
const _a20b1694 = () => interopDefault(import('../pages/news-event/news-list/_cat_slug.vue' /* webpackChunkName: "pages/news-event/news-list/_cat_slug" */))
const _16dfda02 = () => interopDefault(import('../pages/news-event/event-detail/_id/registration/konfirmasi-pembayaran.vue' /* webpackChunkName: "pages/news-event/event-detail/_id/registration/konfirmasi-pembayaran" */))
const _e23b8c38 = () => interopDefault(import('../pages/news-event/event-detail/_id/registration/pemesanan.vue' /* webpackChunkName: "pages/news-event/event-detail/_id/registration/pemesanan" */))
const _472dc898 = () => interopDefault(import('../pages/news-event/event-detail/_id/registration/selesai.vue' /* webpackChunkName: "pages/news-event/event-detail/_id/registration/selesai" */))
const _43504eda = () => interopDefault(import('../pages/news-event/event-detail/_id/registration/finish/_email/_code.vue' /* webpackChunkName: "pages/news-event/event-detail/_id/registration/finish/_email/_code" */))
const _54769204 = () => interopDefault(import('../pages/publikasi/list/_id/files/_slug.vue' /* webpackChunkName: "pages/publikasi/list/_id/files/_slug" */))
const _82939ec0 = () => interopDefault(import('../pages/news-event/event-detail/_id/_slug.vue' /* webpackChunkName: "pages/news-event/event-detail/_id/_slug" */))
const _243bbed2 = () => interopDefault(import('../pages/news-event/gallery/_id/_slug.vue' /* webpackChunkName: "pages/news-event/gallery/_id/_slug" */))
const _1551996a = () => interopDefault(import('../pages/news-event/news-detail/_id/_slug.vue' /* webpackChunkName: "pages/news-event/news-detail/_id/_slug" */))
const _4982d8ee = () => interopDefault(import('../pages/publikasi/detail/_id/_slug.vue' /* webpackChunkName: "pages/publikasi/detail/_id/_slug" */))
const _05f6485f = () => interopDefault(import('../pages/publikasi/list/_id/_cat_slug.vue' /* webpackChunkName: "pages/publikasi/list/_id/_cat_slug" */))
const _0c25c0e2 = () => interopDefault(import('../pages/tentang-kami/peraturan-pedoman-organisasi/_id/_slug.vue' /* webpackChunkName: "pages/tentang-kami/peraturan-pedoman-organisasi/_id/_slug" */))
const _4c774288 = () => interopDefault(import('../pages/tentang-kami/uu-ad-art-kadin/_id/_slug.vue' /* webpackChunkName: "pages/tentang-kami/uu-ad-art-kadin/_id/_slug" */))
const _105b94a0 = () => interopDefault(import('../pages/rapimnas2019/selesai/_email/_code/_order.vue' /* webpackChunkName: "pages/rapimnas2019/selesai/_email/_code/_order" */))
const _1f22afdb = () => interopDefault(import('../pages/cari/_text.vue' /* webpackChunkName: "pages/cari/_text" */))
const _72c06183 = () => interopDefault(import('../pages/kadinpedia/_id/_slug.vue' /* webpackChunkName: "pages/kadinpedia/_id/_slug" */))
const _31028463 = () => interopDefault(import('../pages/kadintalks/_id/_slug.vue' /* webpackChunkName: "pages/kadintalks/_id/_slug" */))
const _05e69def = () => interopDefault(import('../pages/keanggotaan/_id/_slug.vue' /* webpackChunkName: "pages/keanggotaan/_id/_slug" */))
const _ba55daaa = () => interopDefault(import('../pages/layanan/_id/_slug.vue' /* webpackChunkName: "pages/layanan/_id/_slug" */))
const _28ef1c19 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/admin",
      component: _153fe3ce,
      name: "admin___id"
    }, {
      path: "/berita-seputar-kadin",
      component: _0477c2fa,
      name: "berita-seputar-kadin___id"
    }, {
      path: "/covid-19",
      component: _0c674b1c,
      name: "covid-19___id"
    }, {
      path: "/emagazine",
      component: _8038b47a,
      name: "emagazine___id"
    }, {
      path: "/karir",
      component: _13c27468,
      name: "karir___id"
    }, {
      path: "/keanggotaan",
      component: _ac8bab68,
      name: "keanggotaan___id"
    }, {
      path: "/kebijakan-privasi",
      component: _7b34d3a2,
      name: "kebijakan-privasi___id"
    }, {
      path: "/kontak-kami",
      component: _d0a8d15c,
      name: "kontak-kami___id"
    }, {
      path: "/layanan",
      component: _2ab85d08,
      name: "layanan___id"
    }, {
      path: "/login",
      component: _2179bc9a,
      name: "login___id"
    }, {
      path: "/news-event",
      component: _6bca592c,
      name: "news-event___id"
    }, {
      path: "/publikasi",
      component: _0c1770f0,
      name: "publikasi___id"
    }, {
      path: "/rapimnas2019",
      component: _1371e9ed,
      name: "rapimnas2019___id"
    }, {
      path: "/seminarciptakerja",
      component: _9d0a9e9a,
      name: "seminarciptakerja___id"
    }, {
      path: "/syarat-ketentuan",
      component: _2525b977,
      name: "syarat-ketentuan___id"
    }, {
      path: "/undangan-rapimnas-2019",
      component: _280a37a0,
      name: "undangan-rapimnas-2019___id"
    }, {
      path: "/admin/articles",
      component: _6ce39a49,
      name: "admin-articles___id"
    }, {
      path: "/admin/categories",
      component: _7c207b28,
      name: "admin-categories___id"
    }, {
      path: "/admin/covid-19",
      component: _aa32cdc8,
      name: "admin-covid-19___id"
    }, {
      path: "/admin/emagazine",
      component: _1d63d07a,
      name: "admin-emagazine___id"
    }, {
      path: "/admin/emailbox",
      component: _031c1e3b,
      name: "admin-emailbox___id"
    }, {
      path: "/admin/events",
      component: _d7654336,
      name: "admin-events___id"
    }, {
      path: "/admin/files",
      component: _579f6541,
      name: "admin-files___id"
    }, {
      path: "/admin/gallery",
      component: _4f2dac9c,
      name: "admin-gallery___id"
    }, {
      path: "/admin/homeslider",
      component: _38a2128c,
      name: "admin-homeslider___id"
    }, {
      path: "/admin/kadinpedia",
      component: _851eb440,
      name: "admin-kadinpedia___id"
    }, {
      path: "/admin/kadintalks",
      component: _ce118e80,
      name: "admin-kadintalks___id"
    }, {
      path: "/admin/members",
      component: _79749cba,
      name: "admin-members___id"
    }, {
      path: "/admin/news",
      component: _0c816042,
      name: "admin-news___id"
    }, {
      path: "/admin/publication",
      component: _38cc9f76,
      name: "admin-publication___id"
    }, {
      path: "/admin/publication-download-history",
      component: _b65998c8,
      name: "admin-publication-download-history___id"
    }, {
      path: "/admin/seminarciptakerja",
      component: _525322b3,
      name: "admin-seminarciptakerja___id"
    }, {
      path: "/admin/services",
      component: _47c539ac,
      name: "admin-services___id"
    }, {
      path: "/admin/settings",
      component: _3a8edcaf,
      name: "admin-settings___id"
    }, {
      path: "/kadinpedia/list",
      component: _ed06b000,
      name: "kadinpedia-list___id"
    }, {
      path: "/kadintalks/list",
      component: _292f8dc0,
      name: "kadintalks-list___id"
    }, {
      path: "/rapimnas2019/kedatangan",
      component: _3a7eed9f,
      name: "rapimnas2019-kedatangan___id"
    }, {
      path: "/rapimnas2019/konfirmasi-pembayaran",
      component: _2386cc19,
      name: "rapimnas2019-konfirmasi-pembayaran___id"
    }, {
      path: "/rapimnas2019/landing",
      component: _0b22fbf2,
      name: "rapimnas2019-landing___id"
    }, {
      path: "/seminarciptakerja/pendaftaran",
      component: _6612bde3,
      name: "seminarciptakerja-pendaftaran___id"
    }, {
      path: "/tentang-kami/lambang-mars-hymne",
      component: _47998c5c,
      name: "tentang-kami-lambang-mars-hymne___id"
    }, {
      path: "/tentang-kami/peraturan-pedoman-organisasi",
      component: _63e28282,
      name: "tentang-kami-peraturan-pedoman-organisasi___id"
    }, {
      path: "/tentang-kami/sejarah",
      component: _def62b34,
      name: "tentang-kami-sejarah___id"
    }, {
      path: "/tentang-kami/struktur-organisasi",
      component: _0c9b2495,
      name: "tentang-kami-struktur-organisasi___id"
    }, {
      path: "/tentang-kami/uu-ad-art-kadin",
      component: _3eff0365,
      name: "tentang-kami-uu-ad-art-kadin___id"
    }, {
      path: "/tentang-kami/visi-misi",
      component: _44f9f128,
      name: "tentang-kami-visi-misi___id"
    }, {
      path: "/admin/about-us/history",
      component: _cbbf8022,
      name: "admin-about-us-history___id"
    }, {
      path: "/admin/about-us/hymn-march-symbol",
      component: _2b2d9935,
      name: "admin-about-us-hymn-march-symbol___id"
    }, {
      path: "/admin/about-us/organization-structure",
      component: _13de8231,
      name: "admin-about-us-organization-structure___id"
    }, {
      path: "/admin/about-us/rules",
      component: _3aefd6dc,
      name: "admin-about-us-rules___id"
    }, {
      path: "/admin/about-us/uu-ad-art-kadin",
      component: _4f75cdc7,
      name: "admin-about-us-uu-ad-art-kadin___id"
    }, {
      path: "/admin/about-us/visi-misi",
      component: _511cd4ce,
      name: "admin-about-us-visi-misi___id"
    }, {
      path: "/admin/categories/create",
      component: _2fa33496,
      name: "admin-categories-create___id"
    }, {
      path: "/admin/emagazine/create",
      component: _60292b5b,
      name: "admin-emagazine-create___id"
    }, {
      path: "/admin/events/create",
      component: _1d12b8f9,
      name: "admin-events-create___id"
    }, {
      path: "/admin/files/create",
      component: _77fce4c6,
      name: "admin-files-create___id"
    }, {
      path: "/admin/gallery/create",
      component: _83879cbc,
      name: "admin-gallery-create___id"
    }, {
      path: "/admin/homeslider/create",
      component: _035489b2,
      name: "admin-homeslider-create___id"
    }, {
      path: "/admin/kadinpedia/create",
      component: _185860de,
      name: "admin-kadinpedia-create___id"
    }, {
      path: "/admin/kadintalks/create",
      component: _a4b7ac04,
      name: "admin-kadintalks-create___id"
    }, {
      path: "/admin/members/create",
      component: _65b6650a,
      name: "admin-members-create___id"
    }, {
      path: "/admin/news/create",
      component: _65def6bf,
      name: "admin-news-create___id"
    }, {
      path: "/admin/publication/create",
      component: _087b9a08,
      name: "admin-publication-create___id"
    }, {
      path: "/admin/services/create",
      component: _61796658,
      name: "admin-services-create___id"
    }, {
      path: "/admin/about-us/history/ketuakadin",
      component: _2a0ab903,
      name: "admin-about-us-history-ketuakadin___id"
    }, {
      path: "/admin/about-us/rules/create",
      component: _d3a26f28,
      name: "admin-about-us-rules-create___id"
    }, {
      path: "/admin/about-us/uu-ad-art-kadin/create",
      component: _720f9452,
      name: "admin-about-us-uu-ad-art-kadin-create___id"
    }, {
      path: "/admin/about-us/history/ketuakadin/create",
      component: _3f00b21b,
      name: "admin-about-us-history-ketuakadin-create___id"
    }, {
      path: "/admin/about-us/history/ketuakadin/translate/:id",
      component: _07d3a52a,
      name: "admin-about-us-history-ketuakadin-translate-id___id"
    }, {
      path: "/admin/about-us/rules/translate/:id",
      component: _190001b9,
      name: "admin-about-us-rules-translate-id___id"
    }, {
      path: "/admin/about-us/uu-ad-art-kadin/translate/:id?",
      component: _6f4fb5ee,
      name: "admin-about-us-uu-ad-art-kadin-translate-id___id"
    }, {
      path: "/admin/categories/translate/:id?",
      component: _82291b62,
      name: "admin-categories-translate-id___id"
    }, {
      path: "/admin/events/participants/:id?",
      component: _21345508,
      name: "admin-events-participants-id___id"
    }, {
      path: "/admin/events/registrants/:id?",
      component: _7c1657a6,
      name: "admin-events-registrants-id___id"
    }, {
      path: "/admin/events/translate/:id?",
      component: _24586ee8,
      name: "admin-events-translate-id___id"
    }, {
      path: "/admin/gallery/translate/:id?",
      component: _7d39efc3,
      name: "admin-gallery-translate-id___id"
    }, {
      path: "/admin/homeslider/translate/:id?",
      component: _9abd349a,
      name: "admin-homeslider-translate-id___id"
    }, {
      path: "/admin/kadinpedia/gallery/:id?",
      component: _879ce6ea,
      name: "admin-kadinpedia-gallery-id___id"
    }, {
      path: "/admin/kadinpedia/translate/:id?",
      component: _7ba16507,
      name: "admin-kadinpedia-translate-id___id"
    }, {
      path: "/admin/kadintalks/translate/:id?",
      component: _345717e7,
      name: "admin-kadintalks-translate-id___id"
    }, {
      path: "/admin/members/translate/:id?",
      component: _8548046c,
      name: "admin-members-translate-id___id"
    }, {
      path: "/admin/news/translate/:id?",
      component: _6761a006,
      name: "admin-news-translate-id___id"
    }, {
      path: "/admin/publication/files/:id?",
      component: _069238c9,
      name: "admin-publication-files-id___id"
    }, {
      path: "/admin/publication/translate/:id?",
      component: _47e52ec6,
      name: "admin-publication-translate-id___id"
    }, {
      path: "/admin/services/translate/:id?",
      component: _dfed8f5e,
      name: "admin-services-translate-id___id"
    }, {
      path: "/admin/publication/files/:id?/create",
      component: _192da9d6,
      name: "admin-publication-files-id-create___id"
    }, {
      path: "/admin/kadintalks/bumper/:id?/:slug?",
      component: _2f19124b,
      name: "admin-kadintalks-bumper-id-slug___id"
    }, {
      path: "/admin/publication/files/:id?/:fileid",
      component: _6aa4f2ed,
      name: "admin-publication-files-id-fileid___id"
    }, {
      path: "/news-event/gallery/list/:id?/:slug?",
      component: _5b224ab7,
      name: "news-event-gallery-list-id-slug___id"
    }, {
      path: "/admin/gallery/:id",
      component: _54362604,
      name: "admin-gallery-id___id"
    }, {
      path: "/admin/publication-download-history/:id",
      component: _13916d04,
      name: "admin-publication-download-history-id___id"
    }, {
      path: "/news-event/event-list/:cat_slug",
      component: _2532c34b,
      name: "news-event-event-list-cat_slug___id"
    }, {
      path: "/news-event/news-list/:cat_slug",
      component: _a20b1694,
      name: "news-event-news-list-cat_slug___id"
    }, {
      path: "/news-event/event-detail/:id/registration/konfirmasi-pembayaran",
      component: _16dfda02,
      name: "news-event-event-detail-id-registration-konfirmasi-pembayaran___id"
    }, {
      path: "/news-event/event-detail/:id/registration/pemesanan",
      component: _e23b8c38,
      name: "news-event-event-detail-id-registration-pemesanan___id"
    }, {
      path: "/news-event/event-detail/:id/registration/selesai",
      component: _472dc898,
      name: "news-event-event-detail-id-registration-selesai___id"
    }, {
      path: "/news-event/event-detail/:id/registration/finish/:email?/:code?",
      component: _43504eda,
      name: "news-event-event-detail-id-registration-finish-email-code___id"
    }, {
      path: "/publikasi/list/:id?/files/:slug?",
      component: _54769204,
      name: "publikasi-list-id-files-slug___id"
    }, {
      path: "/news-event/event-detail/:id/:slug?",
      component: _82939ec0,
      name: "news-event-event-detail-id-slug___id"
    }, {
      path: "/news-event/gallery/:id/:slug?",
      component: _243bbed2,
      name: "news-event-gallery-id-slug___id"
    }, {
      path: "/news-event/news-detail/:id/:slug?",
      component: _1551996a,
      name: "news-event-news-detail-id-slug___id"
    }, {
      path: "/publikasi/detail/:id?/:slug?",
      component: _4982d8ee,
      name: "publikasi-detail-id-slug___id"
    }, {
      path: "/publikasi/list/:id?/:cat_slug?",
      component: _05f6485f,
      name: "publikasi-list-id-cat_slug___id"
    }, {
      path: "/tentang-kami/peraturan-pedoman-organisasi/:id?/:slug?",
      component: _0c25c0e2,
      name: "tentang-kami-peraturan-pedoman-organisasi-id-slug___id"
    }, {
      path: "/tentang-kami/uu-ad-art-kadin/:id?/:slug?",
      component: _4c774288,
      name: "tentang-kami-uu-ad-art-kadin-id-slug___id"
    }, {
      path: "/rapimnas2019/selesai/:email?/:code?/:order?",
      component: _105b94a0,
      name: "rapimnas2019-selesai-email-code-order___id"
    }, {
      path: "/cari/:text?",
      component: _1f22afdb,
      name: "cari-text___id"
    }, {
      path: "/kadinpedia/:id?/:slug?",
      component: _72c06183,
      name: "kadinpedia-id-slug___id"
    }, {
      path: "/kadintalks/:id?/:slug?",
      component: _31028463,
      name: "kadintalks-id-slug___id"
    }, {
      path: "/keanggotaan/:id/:slug?",
      component: _05e69def,
      name: "keanggotaan-id-slug___id"
    }, {
      path: "/layanan/:id/:slug?",
      component: _ba55daaa,
      name: "layanan-id-slug___id"
    }, {
      path: "/",
      component: _28ef1c19,
      name: "index___id"
    }],

    fallback: false
  })
}
