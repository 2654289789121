export const state = () => ({
    list: [],
    single:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
}

export const actions = {
    async getAllByLanguage({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/services/'+payload.language_id+'?'+queryString)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/services/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/services/'+payload.language_id+'/'+payload.id+'/'+payload.slug);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/services/create',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/services/update',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/services/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async togglePublish({commit},payload){
        try{
            let res = await this.$axios.$post('/services/toggle-publish',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
}