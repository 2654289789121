export const state = () => ({
    list: [],
    single: [],
});

export const mutations = {
    setList(state, data) {
        state.list = data;
    },
    set(state, data) {
        state.single = data;
    }
}

export const actions = {
    async getAllByLanguage({ commit }, payload) {
        try {
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/events-registrants/' + payload.language_id + '/' + payload.event_id + '?' + queryString)
            if (res.status) {
                commit('setList', res.data);
            }
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async get({ commit }, payload) {
        try {
            let res = await this.$axios.$get('/events-registrants/get/' + payload.id);
            if (res.status) {
                commit('set', res.data);
            }
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async create({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/create', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async update({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/update', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async updateStatus({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/updateStatus', payload);
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async cekKode({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/cekKode', payload);
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async delete({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/delete', { id: payload.id });
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async konfirmasiBayar({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/konfirmasiBayar', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async konfirmasiKedatangan({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/konfirmasiKedatangan', payload);
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async diwakilkan({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/diwakilkan', payload);
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async tidakDiwakilkan({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/tidakDiwakilkan', payload);
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
    async sendBuktiPemesanan({ commit }, payload) {
        try {
            let res = await this.$axios.$post('/events-registrants/sendBuktiPemesanan', payload);
            return res;
        } catch (error) {
            return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
        }
    },
}