export const state = () => ({
    list: [],
    single:[],
    uploading:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
    setUploading(state,data){
        state.uploading = data;
    }
}

export const actions = {
    async getAllByLanguage({commit},payload) {
        try{
            let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/kadintalks/'+payload.language_id+'?'+queryString)
            if(res.status){
                if(typeof payload.single!='undefined' && payload.single){
                    commit('set',res.data);
                }
                else{
                    commit('setList', res.data);
                }
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async getByLanguage({commit},payload){
        try{
            let res = await this.$axios.$get('/kadintalks/'+payload.language_id+'/'+payload.id+'/'+payload.slug);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/kadintalks/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/kadintalks/create',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent){
                    commit('setUploading',parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total)));
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async bumperCreate({commit},payload){
        try{
            let res = await this.$axios.$post('/kadintalks/bumper/create/'+payload.id,payload.data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent){
                    commit('setUploading',parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total)));
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async update({commit},payload){
        try{
            let res = await this.$axios.$post('/kadintalks/update',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent){
                    commit('setUploading',parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total)));
                }
            });
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/kadintalks/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async deleteBumper({commit},payload){
        try{
            let res = await this.$axios.$post('/kadintalks/bumper/delete',{id:payload});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async togglePublish({commit},payload){
        try{
            let res = await this.$axios.$post('/kadintalks/toggle-publish',payload);
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async setUploading({commit},payload){
        commit('setUploading',payload);
    }
}