export const state = () => ({
  list: [],
  single: [],
});

export const mutations = {
  setList(state, data) {
    state.list = data;
  },
  set(state, data) {
    state.single = data;
  },
}

export const actions = {
  async getAll({ commit }, payload) {
    try {
      var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/emailbox?' + queryString)
      if (res.status) {
        commit('setList', res.data);
      }
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async reply({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/emailbox/reply', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
}