export const state = () => ({
  list: [],
  single: [],
  uploading: 0,
  listMagazine: [],
  recentMagazine: [],
});

export const mutations = {
  setList(state, data) {
    state.list = data;
  },
  set(state, data) {
    state.single = data;
  },
  setUploading(state, data) {
    state.uploading = data;
  },
  setListMagazine(state, data) {
    state.listMagazine = data;
  },
  setRecentMagazine(state, data) {
    state.recentMagazine = data;
  }
}

export const actions = {
  async getAll({ commit }, payload) {
    try {
      let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/emagazine?' + queryString)
      if (res.status) {
        commit('setList', res.data);
      }
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async getAllMagazine({ commit }, payload) {
    try {
      let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/emagazine/all?' + queryString)
      if (res.status) {
        commit('setList', res.data);
      }
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async create({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/emagazine/create', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
          commit('setUploading', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
        }
      });
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async setUploading({ commit }, payload) {
    commit('setUploading', payload);
  },
  async delete({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/emagazine/delete', { id: payload.id });
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } };
    }
  },
  async togglePublish({ commit }, payload) {
    try {
      let res = await this.$axios.$post('/emagazine/toggle-publish', payload);
      return res;
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } }
    }
  },
  async getListMagazine({ commit }, payload) {
    try {
      let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/emagazine?' + queryString);
      commit('setListMagazine', res.data)
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } }
    }
  },
  async getRecentMagazine({ commit }, payload) {
    try {
      let queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
      let res = await this.$axios.$get('/emagazine?' + queryString);
      commit('setRecentMagazine', res.data)
    } catch (error) {
      return { status: false, error: { message: 'Terjadi kesalahan pada server.' } }
    }
  }
}