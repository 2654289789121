
const Cookie = process.client ? require('js-cookie') : undefined
export default function ({ $axios, store,redirect }) {
    $axios.onRequest((config) => {
        if(store.state.auth!=null){
            config.headers.common['Authorization'] = `Bearer ${store.state.auth.accessToken}`;
        }
    })
    $axios.onError((error) => {
        if(error){
            const code = parseInt(error.response.status);
            if (code === 401) {
                store.commit('setAuth',null);
                Cookie.remove('auth');
                return redirect('/login');
            }
        }
    })
}