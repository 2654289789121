import Vue from 'vue';
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js';

import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
import 'trumbowyg/dist/plugins/resizimg/trumbowyg.resizimg.js';
import 'trumbowyg/dist/plugins/allowtagsfrompaste/trumbowyg.allowtagsfrompaste.js'
import 'jquery-resizable-dom';
Vue.use(VueTrumbowyg);