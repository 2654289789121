<template>
  <div>
    <div class="sidebar">
      <div class="logo-wrap">
        <b-link href="/">
          <img v-lazy="require('~/static/logo.png')" alt="Kadin">
          <span>KADIN INDONESIA</span>
        </b-link>
      </div>
      <div class="menu-wrap">
        <h6>Main Menu</h6>
        <ul class="admin-menu">
            <li>
                <b-link to="/admin/categories"><i class="fas fa-th-list"></i> Kategori</b-link>
            </li>
          <li>
            <b-link v-b-toggle.multi1><i class="fas fa-newspaper"></i> Berita & Event</b-link>
            <b-collapse id="multi1">
                <ul class="child1">
                    <li>
                        <b-link to="/admin/news"><i class="fas fa-newspaper"></i> Berita</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/events"><i class="fas fa-table"></i> Events</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/articles"><i class="fas fa-newspaper"></i> Berita Seputar Kadin</b-link>
                    </li>
                    <li>
                        <!-- <b-link to="/admin/covid-19"><i class="fas fa-newspaper"></i> Berita Seputar Covid-19</b-link> -->
                    </li>
                    <!-- <li>
                    <b-link v-b-toggle.multi2><i class="fas fa-newspaper"></i> Child 1</b-link>
                    <b-collapse id="multi2"> 
                        <ul class="child2">
                        <li>
                            <b-link to=""><i class="fas fa-newspaper"></i> Child 2</b-link>
                        </li>
                        </ul>
                    </b-collapse>
                    </li> -->
                </ul>
            </b-collapse>
          </li>
          <li>
            <b-link to="/admin/homeslider"><i class="fas fa-sliders-h"></i> Homeslider</b-link>
          </li>
          <li>
            <b-link to="/admin/gallery"><i class="fas fa-image"></i> Galeri</b-link>
          </li>
          <!-- <li>
            <b-link to="/admin/emagazine"><i class="fas fa-file-pdf"></i> E-Magazine</b-link>
          </li>
          <li>
            <b-link to="/admin/kadintalks"><i class="fas fa-video"></i> Kadin Talks</b-link>
          </li> -->
          <li>
            <b-link to="/admin/kadinpedia"><i class="fas fa-book"></i> Kadinpedia</b-link>
          </li>
          <li>
            <b-link to="/admin/publication"><i class="fas fa-file-pdf"></i> Publikasi</b-link>
          </li>
          <li>
            <b-link to="/admin/publication-download-history"><i class="fas fa-file-pdf"></i> History Unduh Publikasi</b-link>
          </li>
          <li>
            <b-link to="/admin/services"><i class="fas fa-users-cog"></i> Layanan</b-link>
          </li>
          <li>
            <b-link to="/admin/members"><i class="fas fa-users"></i> Keanggotaan</b-link>
          </li>
          <li>
            <b-link v-b-toggle.multi2><i class="fas fa-info-circle"></i> Tentang Kami</b-link>
            <b-collapse id="multi2">
                <ul class="child1">
                    <li>
                        <b-link to="/admin/about-us/history"><i class="fas fa-history"></i> Sejarah</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/about-us/uu-ad-art-kadin"><i class="fas fa-history"></i> UU / AD / ART Kadin</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/about-us/hymn-march-symbol"><i class="fas fa-history"></i> Lambang Mars & Symbol</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/about-us/rules"><i class="fas fa-history"></i> Peraturan dan Pedoman Organisasi</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/about-us/organization-structure"><i class="fas fa-history"></i> Struktur Organisasi</b-link>
                    </li>
                    <li>
                        <b-link to="/admin/about-us/visi-misi"><i class="fas fa-history"></i> Visi & Misi</b-link>
                    </li>
                </ul>
            </b-collapse>
          </li>
          <li>
            <b-link to="/admin/files"><i class="fas fa-file"></i>File Publik</b-link>
          </li>
          <li>
            <b-link to="/admin/settings"><i class="fas fa-cog"></i>Web Settings</b-link>
          </li>
          <li>
            <b-link to="/admin/emailbox"><i class="fas fa-inbox"></i>Mailbox</b-link>
          </li>
          <li>
            <b-link to="/admin/seminarciptakerja"><i class="fas fa-user"></i>Peserta Seminar Cipta Kerja</b-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="content ml-auto">
      <div class="content-head">
        <b-dropdown id="ddown1" text="" class="account">
          <b-dropdown-item @click="logout">Log Out</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="content-body admin"> 
        <nuxt/>
      </div>
    </div>
  </div>
</template>
<script>
const Cookie = process.client ? require('js-cookie') : undefined
export default {
    methods:{
        logout() {
            Cookie.remove('auth');
            this.$store.commit('setAuth', null);
            this.$router.push('/login');
        }
    }
}
</script>
