<template>
    <div>

      <div class="content-body"> 
        <nuxt/>
      </div>

      <footer class="gray">
        <b-container>
          <b-row class="align-items-center">
            <b-col lg="5" md="12" class="cright">
              <p>Copyright © Kadin.id {{new Date().getFullYear()}}. All rights reserved.</p>
            </b-col>
            <b-col lg="2" md="12" class="socmed">
                <a v-show="typeof settings.facebook_url!='undefined' && settings.facebook_url!=''" :href="typeof settings.facebook_url!='undefined' ? settings.facebook_url : '#'"><i class="fab fa-facebook-f"></i></a>
                <a v-show="typeof settings.twitter_url!='undefined' && settings.twitter_url!=''" :href="typeof settings.twitter_url!='undefined' ? settings.twitter_url : '#'"><i class="fab fa-twitter"></i></a>
                <a v-show="typeof settings.youtube_url!='undefined' && settings.youtube_url!=''" :href="typeof settings.youtube_url!='undefined' ? settings.youtube_url : '#'"><i class="fab fa-youtube"></i></a>
                <a v-show="typeof settings.instagram_url!='undefined'&& settings.instagram_url!=''" :href="typeof settings.instagram_url!='undefined' ? settings.instagram_url : '#'"><i class="fab fa-instagram"></i></a>
            </b-col>
            <b-col lg="5" md="12" class="other-link">
              <nuxt-link to="/kebijakan-privasi">{{$t('pages.kebijakanprivasi')}}</nuxt-link>
              <nuxt-link to="/syarat-ketentuan">{{$t('pages.syaratketentuan')}}</nuxt-link>
              <nuxt-link to="/karir">{{$t('pages.karir')}}</nuxt-link>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </div>
</template>

<script>
export default {
    computed:{
        settings(){
            let data = this.$store.state.pageSettings;
            let form = {};
            data.forEach((d)=>{
                form[d.key]=d.value;
            });
            return form;
        },
        menu(){
            return this.$store.state.subMenu;
        }
    },
    data:function(){
        return{
            isOpen: '',
            search: '',
        }
    },
    methods:{
        onSearch(evt){
            evt.preventDefault();
            if(this.search && this.search!=''){
                this.$router.push(this.localePath({
                    name:'cari-text',
                    params:{
                        text:this.search
                    }
                }));
            }
        }
    }
}
</script>
