export const state = () => ({
    list: [],
    single:[],
    uploading:[],
});
  
export const mutations = {
    setList (state,data){
        state.list = data;
    },
    set(state,data){
        state.single = data;
    },
    setUploading(state,data){
        state.uploading = data;
    }
}

export const actions = {
    async getAll({commit},payload) {
        try{
            var queryString = payload ? Object.keys(payload).map(key => key + '=' + payload[key]).join('&') : '';
            let res = await this.$axios.$get('/files/getAll?'+queryString)
            if(res.status){
                commit('setList', res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async get({commit},payload){
        try{
            let res = await this.$axios.$get('/files/get/'+payload.id);
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async create({commit},payload){
        try{
            let res = await this.$axios.$post('/files/create/',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent){
                    commit('setUploading',parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total)));
                }
            });
            if(res.status){
                commit('set',res.data);
            }
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async delete({commit},payload){
        try{
            let res = await this.$axios.$post('/files/delete',{id:payload.id});
            return res;
        }catch(error){
            return {status:false,error:{message:'Terjadi kesalahan pada server.'}};
        }
    },
    async setUploading({commit},payload){
        commit('setUploading',payload);
    }
}